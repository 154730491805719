<template>
  <DashboardAdminTemplate v-loading.fullscreen.lock="fullscreenLoading">
    <div class="pd-x-5 pd-y-3">
      <div>
        <router-link to="/user/list" class="text-grey-light font-18"
          ><i class="el-icon-arrow-left"></i> ย้อนกลับ</router-link
        >
      </div>
      <div>
        <h2>จัดการหน้าผู้ใช้งาน</h2>
      </div>
      <div>
        <p>จัดการเมนู</p>
        <el-tooltip class="item" effect="dark" placement="bottom">
          <div slot="content">
            1. ปุ่ม ดาวน์โหลดผลประเมิน<br />2. เครื่องมือที่คำแนะนำ<br />3.
            หลักสูตรที่แนะนำ
          </div>
          <span>
            <el-switch v-model="openMenu" @change="updateopenMenu"> </el-switch>
            {{ openMenu ? "เปิดเมนู" : "ปิดเมนู" }}
          </span>
        </el-tooltip>
      </div>
      <el-divider></el-divider>
      <div>
        <h4>จัดการภาพแบนเนอร์หน้าเริ่มต้นและแนบลิงก์ URL</h4>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <p class="mg-b-6">แนบไฟล์</p>
          <el-upload
            class="w-100 banner-upload"
            action="#"
            :auto-upload="false"
            :on-change="handlePreview"
            :limit="1"
            :file-list="fileList"
          >
            <el-button size="small" type="primary" class="w-100"
              >แนบไฟล์</el-button
            >
          </el-upload>
          <p class="font-18">
            ขนาดรูปภาพที่แนะนำ (1140x150), นามสกุลไฟล์ (.jpg .jpeg .png .gif)
          </p>
        </el-col>
        <el-col :span="12">
          <p class="mg-b-6">แนบลิงก์ URL</p>
          <el-input v-model="linkBanner" placeholder="https://example.com" />
        </el-col>
        <el-col :span="24" v-if="imgBanner">
          <p>
            แสดงรูปภาพแบนเนอร์
            <a class="mg-l-5" @click="deleteBanner(imgBanner.id)">ลบแบนเนอร์</a>
          </p>
          <a :href="linkBanner">
            <img
              :src="`${imgUrl}${imgBanner.img}`"
              class="mw-100"
              alt="bannerZero"
            />
          </a>
        </el-col>
        <el-col :span="24">
          <el-button
            size="small"
            type="primary"
            style="width: 130px"
            class="mg-t-3"
            @click="submitData(fileList, linkBanner, imgBanner, 'banner')"
            >บันทึก</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div>
        <h4>จัดการภาพแบนเนอร์หน้าฟีเจอร์พิเศษและแนบลิงก์ URL ภาพที่ 1</h4>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <p class="mg-b-6">แนบไฟล์</p>
          <el-upload
            class="w-100 banner-upload"
            action="#"
            :auto-upload="false"
            :on-change="handleOne"
            :limit="1"
            :file-list="fileOne"
          >
            <el-button size="small" type="primary" class="w-100"
              >แนบไฟล์</el-button
            >
          </el-upload>
          <p class="font-18">
            ขนาดรูปภาพที่แนะนำ (1140x150), นามสกุลไฟล์ (.jpg .jpeg .png .gif)
          </p>
        </el-col>
        <el-col :span="12">
          <p class="mg-b-6">แนบลิงก์ URL</p>
          <el-input v-model="linkOne" placeholder="https://example.com" />
        </el-col>
        <el-col :span="24" v-if="bannerOne">
          <p>
            แสดงรูปภาพแบนเนอร์
            <a class="mg-l-5" @click="deleteBanner(bannerOne.id)">ลบแบนเนอร์</a>
          </p>
          <a :href="linkOne">
            <img
              :src="`${imgUrl}${bannerOne.img}`"
              class="mw-100"
              alt="bannerOne"
            />
          </a>
        </el-col>
        <el-col :span="24">
          <el-button
            size="small"
            type="primary"
            style="width: 130px"
            class="mg-t-3"
            @click="submitData(fileOne, linkOne, bannerOne, 'featureOne')"
            >บันทึก</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div>
        <h4>จัดการภาพแบนเนอร์หน้าฟีเจอร์พิเศษและแนบลิงก์ URL ภาพที่ 2</h4>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <p class="mg-b-6">แนบไฟล์</p>
          <el-upload
            class="w-100 banner-upload"
            action="#"
            :auto-upload="false"
            :on-change="handleTwo"
            :limit="1"
            :file-list="fileTwo"
          >
            <el-button size="small" type="primary" class="w-100"
              >แนบไฟล์</el-button
            >
          </el-upload>
          <p class="font-18">
            ขนาดรูปภาพที่แนะนำ (1140x150), นามสกุลไฟล์ (.jpg .jpeg .png .gif)
          </p>
        </el-col>
        <el-col :span="12">
          <p class="mg-b-6">แนบลิงก์ URL</p>
          <el-input v-model="linkTwo" placeholder="https://example.com" />
        </el-col>
        <el-col :span="24" v-if="bannerTwo">
          <p>
            แสดงรูปภาพแบนเนอร์
            <a class="mg-l-5" @click="deleteBanner(bannerTwo.id)">ลบแบนเนอร์</a>
          </p>
          <a :href="linkTwo">
            <img
              :src="`${imgUrl}${bannerTwo.img}`"
              class="mw-100"
              alt="bannerTwo"
            />
          </a>
        </el-col>
        <el-col :span="24">
          <el-button
            size="small"
            type="primary"
            style="width: 130px"
            class="mg-t-3"
            @click="submitData(fileTwo, linkTwo, bannerTwo, 'featureTwo')"
            >บันทึก</el-button
          >
        </el-col>
      </el-row>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import { HTTP } from "@/service/axios";
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
export default {
  components: {
    DashboardAdminTemplate,
  },
  computed: {
    openMenuStore() {
      return this.$store.state.openMenu;
    },
  },
  data() {
    return {
      openMenu: true,
      imgBanner: null,
      linkBanner: null,
      bannerOne: null,
      linkOne: null,
      bannerTwo: null,
      linkTwo: null,
      fileList: [],
      fileOne: [],
      fileTwo: [],
      imgUrl: process.env.VUE_APP_BANNER_URL,
      fullscreenLoading: true,
    };
  },
  mounted() {
    this.fetchStatusMenu();
    this.getBanner();
  },
  methods: {
    async deleteBanner ( id )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      let res = await HTTP.delete(`/banner/${id}`).catch((e) => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        this.fullscreenLoading = false;
        return {
          data: {
            data: e,
            success: false,
          },
        };
      });
      if (res.data.success) {
        this.$message({
          type: "success",
          message: "ลบแบนเนอร์สำเร็จ",
        });
        this.getBanner();
      }
    },
    handleOne(file, fileList) {
      if (
        file.raw.type != "image/jpeg" &&
        file.raw.type != "image/jpg" &&
        file.raw.type != "image/png" &&
        file.raw.type != "image/gif"
      ) {
        this.$message.error("รองรับรูปแบบไฟล์ .jpg .jpeg .png .gif");
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
      } else if (file.size > 2000000) {
        this.$message.error("ขนาดรูปภาพไม่เกิน 2MB");
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
      } else {
        this.fileOne = fileList;
      }
    },
    handleTwo(file, fileList) {
      if (
        file.raw.type != "image/jpeg" &&
        file.raw.type != "image/jpg" &&
        file.raw.type != "image/png" &&
        file.raw.type != "image/gif"
      ) {
        this.$message.error("รองรับรูปแบบไฟล์ .jpg .jpeg .png .gif");
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
      } else if (file.size > 2000000) {
        this.$message.error("ขนาดรูปภาพไม่เกิน 2MB");
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
      } else {
        this.fileTwo = fileList;
      }
    },
    handlePreview(file, fileList) {
      if (
        file.raw.type != "image/jpeg" &&
        file.raw.type != "image/jpg" &&
        file.raw.type != "image/png" &&
        file.raw.type != "image/gif"
      ) {
        this.$message.error("รองรับรูปแบบไฟล์ .jpg .jpeg .png .gif");
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
      } else if (file.size > 2000000) {
        this.$message.error("ขนาดรูปภาพไม่เกิน 2MB");
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
      } else {
        this.fileList = fileList;
      }
    },
    async submitData(fileList, link, data, type) {
      this.fullscreenLoading = true;
      let obj = {
        file: fileList,
        url: link,
        data: data,
        typeBanner: type,
      };
      // console.log("data", obj);
      let reg = new RegExp(/(http:)|(https:)/, "gi");
      let test = reg.test(link);
      if (!test) {
        obj.url = `https://${link}`;
      }
      if (data) {
        let update = await this.updateBanner(obj);
        if (update.data.success) {
          this.$message({
            type: "success",
            message: "แก้ไขแบนเนอร์สำเร็จ",
          });
        }
      } else {
        if (fileList.length > 0) {
          let create = await this.createBanner(obj);
          if (create.data.success) {
            this.$message({
              type: "success",
              message: "เพิ่มแบนเนอร์สำเร็จ",
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "กรุณาแนบรูปแบนเนอร์",
          });
        }
      }
      this.fileList = [];
      this.getBanner();
    },
    async createBanner(data) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.$csrfToken
        },
      };
      let formData = new FormData();
      formData.append("status", 1);
      formData.append("typeBanner", data.typeBanner);
      formData.append("file", data.file.length > 0 ? data.file[0].raw : "");
      formData.append( "url", data.url );
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      let res = await HTTP.post(`banner`, formData, config).catch((e) => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        this.fullscreenLoading = false;
        return {
          data: {
            data: e,
            success: false,
          },
        };
      });
      return res;
    },
    async updateBanner(data) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.$csrfToken
        },
      };
      let formData = new FormData();
      formData.append("id", data.data.id);
      formData.append("status", 1);
      formData.append("typeBanner", data.typeBanner);
      if (data.file.length > 0) {
        formData.append("file", data.file[0].raw);
      }
      formData.append( "url", data.url );
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      let res = await HTTP.put(`banner`, formData, config).catch((e) => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        this.fullscreenLoading = false;
        return {
          data: {
            data: e,
            success: false,
          },
        };
      });
      return res;
    },
    async getBanner ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.get(`banner`)
        .then((result) => {
          if (result.data.success) {
            let find = result.data.obj.filter(
              (a) => a.typeBanner == null || a.typeBanner == "banner"
            );
            if (find.length > 0) {
              this.imgBanner = find[0];
              this.linkBanner = find[0].url;
            } else {
              this.imgBanner = null;
              this.linkBanner = null;
            }

            let one = result.data.obj.filter(
              (a) => a.typeBanner == "featureOne"
            );
            if (one.length > 0) {
              this.bannerOne = one[0];
              this.linkOne = one[0].url;
            } else {
              this.bannerOne = null;
              this.linkOne = null;
            }
            let two = result.data.obj.filter(
              (a) => a.typeBanner == "featureTwo"
            );
            if (two.length > 0) {
              this.bannerTwo = two[0];
              this.linkTwo = two[0].url;
            } else {
              this.bannerTwo = null;
              this.linkTwo = null;
            }
          } else {
            this.imgBanner = null;
            this.linkBanner = null;
            this.bannerOne = null;
            this.linkOne = null;
            this.bannerTwo = null;
            this.linkTwo = null;
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
      this.fullscreenLoading = false;
    },
    async fetchStatusMenu ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.get(`close/function`)
        .then((res) => {
          if (res.data.success) {
            let status = res.data.obj.status == 1 ? true : false;
            this.$store.commit("SET_OPEN_MENU", status);
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_OPEN_MENU error", e.response);
        });
      this.openMenu = this.openMenuStore;
    },
    updateopenMenu() {
      let obj = {
        status: this.openMenu ? 1 : 0,
      };
      // console.log(obj);
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.put(`close/function`, obj)
        .then((res) => {
          // console.log("updateopenMenu", res);
          if (res.data.success) {
          } else {
            this.alertFailError();
            this.loading = false;
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("updateopenMenu error", e);
        });
    },
  },
};
</script>
